<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-textarea
            v-for="section in sectionsTitles"
            :key="section.key"
            outlined
            name="input-7-4"
            v-model="comments[section?.key]"
            :label="`${commentLabel(section)} Comment`"
            :placeholder="`${commentLabel(section)} Comment`"
            rows="8"
            auto-grow
            :disabled="FIELDS_DISABLED"
          >
          </v-textarea>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'LendingNotesSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialComments: {},
      comments: {},
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED', 'APPLICATION']),

    sectionsTitles() {
      const applicationSection = this.$parent?.$data?.applicationSection;
      const otherSections = this.$parent?.$data?.sections;
      const sectionList = [applicationSection, ...otherSections];

      const filteredSections = sectionList.filter((item) => item.commentShow === true);

      return filteredSections;
    },
  },

  async created() {
    await this.checkIsSectionVisible();
    this.initialComments = clone(this.existedData);
    this.comments = clone(this.existedData);

    this.$emit('componentReady');
  },

  methods: {
    commentLabel(section) {
      const key = section?.key;
      const currentTitle = this.sectionsTitles.filter((item) => item.key === key);
      return currentTitle?.[0]?.title;
    },
  },
};
</script>
