var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, _vm._l(_vm.sectionsTitles, function (section) {
          return _c('v-textarea', {
            key: section.key,
            attrs: {
              "outlined": "",
              "name": "input-7-4",
              "label": "".concat(_vm.commentLabel(section), " Comment"),
              "placeholder": "".concat(_vm.commentLabel(section), " Comment"),
              "rows": "8",
              "auto-grow": "",
              "disabled": _vm.FIELDS_DISABLED
            },
            model: {
              value: _vm.comments[section === null || section === void 0 ? void 0 : section.key],
              callback: function callback($$v) {
                _vm.$set(_vm.comments, section === null || section === void 0 ? void 0 : section.key, $$v);
              },
              expression: "comments[section?.key]"
            }
          });
        }), 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }